import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface State {
  title: string;
  subtitle: string;
  shortSubTitle: string;
  currentStep: boolean;
  rank: number;
  textId: string;
  stageFileId: string;
}

interface ProgressBarProps {
  steps: State[];
  onStepClick: (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string
  ) => void;
}

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 600px;
  z-index: 50;
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 50px;
  border-radius: 20px;
  align-items: center;
  width: 100%;
  height: 10px;
  position: relative;
`;

const ProgressLine = styled.div`
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #393939;
  border-radius: 20px;
  z-index: -1;
`;

const ProgressLineFilled = styled.div<{ width: number }>`
  position: absolute;
  height: 5px;
  background-color: #ff734f;
  width: ${({ width }) => width}px;
  z-index: -1;
  border-radius: 20px;
`;

const StepButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepButton = styled.button<{
  isActive: boolean;
  currentStep: boolean;
  afterCurrentStep: boolean;
  hasTextId: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: ${({ hasTextId }) => (hasTextId ? "20px" : "15px")};
  height: ${({ hasTextId }) => (hasTextId ? "20px" : "15px")};
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepDot = styled.div<{
  isActive: boolean;
  currentStep: boolean;
  afterCurrentStep: boolean;
  hasTextId: boolean;
}>`
  background-color: ${({ afterCurrentStep }) =>
    afterCurrentStep ? "#FF734F" : "#FF734F"};
  box-shadow: ${({ isActive }) => (isActive ? "0 0 0 2px #ffffff" : "none")};
  border-radius: 50%;
  width: ${({ hasTextId }) => (hasTextId ? "15px" : "10px")};
  height: ${({ hasTextId }) => (hasTextId ? "15px" : "10px")};
  transition: background-color 0.3s, width 0.3s, height 0.3s;
`;

const StepMeta = styled.div`
  text-align: center;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 16px;
  font-family: "Sharp Sans";
`;

const Title = styled.span`
  text-align: center;
  font-weight: 700;
  color: #ffffff;
`;

const Description = styled.span`
  text-align: center;
  color: #ffffff;
`;

const FileProgressBar: React.FC<ProgressBarProps> = ({
  steps,
  onStepClick,
}) => {
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [clickedStep, setClickedStep] = useState<number | null>(null);
  const [widthBar, setWidthBar] = useState(0);

  useEffect(() => {
    const currentStep = steps.find((step) => step.currentStep);
    if (currentStep) {
      setActiveStep(currentStep.rank - 1); // Adjusting rank to zero-based index

      // Calculate the total width up to the center of the current step
      const totalWidth = steps.reduce((acc, step, index) => {
        if (index < currentStep.rank - 1) {
          // Add full gap for previous steps
          return acc + (step.textId ? 15 : 10) + 38;
        } else if (index === currentStep.rank - 1) {
          // Add half of the current step's width
          return acc + (step.textId ? 7.5 : 5);
        }
        return acc;
      }, 0);

      setWidthBar(totalWidth);
    }
  }, [steps]);

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    setActiveStep(index);
  };

  const handleMouseLeave = () => {
    if (clickedStep !== null) {
      setActiveStep(clickedStep);
    } else {
      const currentStep = steps.find((step) => step.currentStep);
      if (currentStep) {
        setActiveStep(currentStep.rank - 1); // Adjusting rank to zero-based index
      }
    }
  };

  const handleClick = (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string
  ) => {
    onStepClick(rank, title, subtitle, textId, stageFileId); // Pass text_id
    setActiveStep(rank - 1);
    setClickedStep(rank - 1);
    console.log("Active step :", rank - 1);
    console.log("Active text_id :", textId);
  };

  return (
    <ProgressBarContainer>
      <StepsContainer>
        <ProgressLine />
        <ProgressLineFilled width={widthBar} />
        {steps.map((step, index) => (
          <StepButtonContainer key={index}>
            {step && (
              <StepButton
                isActive={activeStep === index}
                currentStep={step.currentStep}
                afterCurrentStep={step.currentStep}
                hasTextId={!!step.textId} // Check if textId is not empty
                onMouseEnter={(event) => handleMouseEnter(event, index)}
                onMouseLeave={handleMouseLeave}
                onClick={
                  () =>
                    handleClick(
                      step.rank,
                      step.title,
                      step.shortSubTitle,
                      step.textId,
                      step.stageFileId
                    ) // Pass text_id
                }
              >
                <StepDot
                  isActive={activeStep === index}
                  currentStep={step.currentStep}
                  afterCurrentStep={step.currentStep}
                  hasTextId={!!step.textId}
                />
              </StepButton>
            )}
          </StepButtonContainer>
        ))}
      </StepsContainer>
      <StepMeta>
        {activeStep !== null && steps[activeStep] && (
          <>
            <Title>{steps[activeStep].title}</Title>
            <Description>{steps[activeStep].subtitle}</Description>
          </>
        )}
      </StepMeta>
    </ProgressBarContainer>
  );
};

export default FileProgressBar;
