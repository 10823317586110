import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Data {
  x: string;
  y: number;
}

interface ChartData {
  data: Data[];
}

interface UseChartDataResponse {
  data: ChartData[] | null;
  loading: boolean;
  error: string | null;
}

const useChart = (fileId?: string): UseChartDataResponse => {
  const [data, setData] = useState<ChartData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      console.log("fileId", fileId);
      try {
        const response = await axios.get(`${MAIN_URL}/chartmaker/lineChart`, {
          // const response = await axios.get(`http://localhost:1170/lineChart`, {
          params: {
            uid: fileId,
          },
        });
        //const response = await axios.get(`${MAIN_URL}textprovider/files`);
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};

export default useChart;
