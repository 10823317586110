import React, { useState, useLayoutEffect, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import MenuField from "./MenuField";
import alertIcon from "../../assets/img/icons/alertIcon.png";
import logo from "../../assets/img/logos/logo.svg";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import userIcon from "../../assets/img/icons/userDefault.png";
import useViewport from "../../hooks/useViewport";

interface SideBarProps {
  display: boolean;
}

const SideBarHero = styled.nav<{ isLogguedin: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.isLogguedin ? "flex" : "none")};
  height: 100%;
  //display: flex;
  width: 236px;
  box-sizing: border-box;
  padding: 23px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  gap: 37px;
  border-right: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
  justify-content: space-between;
  /* Gestion de l'affichage du menu mobile */
  .display {
    left: 0;
    right: 0;
  }
  z-index: 1000;

  @media (max-width: 1450px) {
    //background-color: red;
    width: 70px;
    transition: width 0.3s ease-in-out;
    &:hover {
      width: 236px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //gap: 36.583px;
  width: 100%;
  //height: 87%;
  padding: 0px 10px 0px 10px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  margin-left: 5px;
  width: 38.931px;
  height: 39.417px;
  margin-bottom: 36px;
`;

const ListNav = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const Account = styled(Link)`
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  max-height: 80px;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid var(--Main-stroke-1, #ebebeb);
  text-decoration: none;
  &:hover {
    background-color: #f0f0f0;
  }
  cursor: pointer;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 17px;
  height: 30px;
`;

const ProfilePic = styled.img`
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
`;

const MyAccount = styled.span<{ isHovered: boolean }>`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  width: 100%;
  @media (max-width: 1450px) {
    display: ${({ isHovered }) => (isHovered ? "block" : "none")};
  }
`;

const SideBar: React.FC = () => {
  const { user } = useAuth();
  const [isHovered, setIsHovered] = useState(false);

  // Gandle over on sidebar
  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log("isHovered", isHovered);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <SideBarHero
      isLogguedin={user}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Content>
        <ListNav>
          <Logo src={logo} />
          <MenuField
            name={"house"}
            text={"Home"}
            iconSrc={alertIcon}
            link={"/"}
            isHovered={isHovered}
          />

          <MenuField
            name={"bell-ring"}
            iconSrc={alertIcon}
            link={"/alerts"}
            text={"Alertes"}
            isHovered={isHovered}
          />
          <MenuField
            name={"chart-column-increasing"}
            iconSrc={alertIcon}
            link={"/chartmaker"}
            text={"Graphiques"}
            isHovered={isHovered}
          />
          <MenuField
            name={"users"}
            iconSrc={alertIcon}
            link={"/"}
            text={"Parlementaires"}
            isHovered={isHovered}
          />
          <MenuField
            name={"book"}
            iconSrc={alertIcon}
            link={"/files"}
            text={"Dossiers législatifs"}
            isHovered={isHovered}
          />
        </ListNav>
      </Content>
      <Account to={"/account"}>
        <Field>
          <ProfilePic src={userIcon} />
          <MyAccount isHovered={isHovered}>Mon compte</MyAccount>
        </Field>
      </Account>
    </SideBarHero>
  );
};

export default SideBar;
