import React from "react";
import styled from "styled-components";

interface PageProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0px auto;
  margin-left: 239px;
  padding: 20px;
  @media (max-width: 1450px) {
    margin-left: 80px;
  }
`;

const PageContainer: React.FC<PageProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PageContainer;
