import React from "react";
import styled from "styled-components";

interface ProfilPicProps {
  deputyId?: string;
  size?: number;
}

const Container = styled.div<{ size?: number }>`
  width: ${({ size }) => size || 24}px;
  height: ${({ size }) => size || 24}px;
  background-color: #3c3c3d;
  border-radius: 50%;
  overflow: hidden;
`;

const Profilimg = styled.img`
  width: 100%;
  height: 100%;
`;

const ProfilPic: React.FC<ProfilPicProps> = ({ deputyId, size }) => {
  return (
    <Container size={size}>
      <Profilimg
        src={
          "https://res.cloudinary.com/dhpdocirj/image/upload/" +
          String(deputyId)
        }
      />
    </Container>
  );
};

export default ProfilPic;
