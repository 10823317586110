import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface State {
  title: string;
  subtitle: string;
  shortSubTitle: string;
  currentStep: boolean;
  rank: number;
  textId: string;
  stageFileId: string;
}

interface Response {
  title: string;
  subtitle: string;
  states: State[];
}

interface UseStateInfoResponse {
  data: Response | null;
  loading: boolean;
  error: string | null;
}

const useStateInfo = (legislativeFileId?: string): UseStateInfoResponse => {
  const [data, setData] = useState<Response | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}textprovider/state`, {
          //const response = await axios.get(`http://localhost:1080/state`, {
          params: {
            uid: legislativeFileId,
          },
        });
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [legislativeFileId]);

  return { data, loading, error };
};

export default useStateInfo;
