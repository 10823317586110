import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Define an interface for the props
interface MenuFieldProps {
  iconSrc: string;
  text: string;
  link: string;
}
const MenuFieldHero = styled.div`
  display: flex;
  padding: 22px 192px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
`;

const Element = styled(Link)`
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
`;

const FileMenu: React.FC<MenuFieldProps> = ({ text, iconSrc, link }) => {
  return (
    <MenuFieldHero>
      <Element to={"overview"}>Overview</Element>
      <Element to={"text"}>Texte</Element>
      <Element to={"amendts"}>Amendements</Element>
      <Element to={"speeches"}>Discussions</Element>
    </MenuFieldHero>
  );
};

export default FileMenu;
