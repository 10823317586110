import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Sharp Sans";
    src: local("Sharp Sans Regular"),
         url("/fonts/Medium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: local("Sharp Sans Medium"),
         url("/fonts/Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: local("Sharp Sans SemiBold"),
         url("/fonts/SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: local("Sharp Sans Bold"),
         url("/fonts/Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Sharp Sans";
    src: local("Sharp Sans ExtraBold"),
         url("/fonts/ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
  }

  * {
    font-family: "Sharp Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyles;
