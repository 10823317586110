import { useState } from "react";
import axios from "axios";
import { MAIN_URL } from "../config";

interface UseFollowResponse {
  isFollowing: boolean;
  toggleFollow: () => Promise<void>;
  error: string | null;
}

const useFollow = (fileId: string, userId: number): UseFollowResponse => {
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const toggleFollow = async () => {
    try {
      const method = isFollowing ? "DELETE" : "POST";
      const response = await axios({
        method,
        url: `${MAIN_URL}/follow/follow`,
        data: {
          user_id: userId,
          entity_id: fileId,
          type: "File",
        },
      });

      if (response.status === 200) {
        setIsFollowing(!isFollowing);
      }
    } catch (err) {
      setError("An error occurred while toggling follow status");
      console.error("Follow error:", err);
    }
  };

  return { isFollowing, toggleFollow, error };
};

export default useFollow;
