import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface File {
  code: string;
  name: string;
  uid: string;
  wording: string;
  status: string;
  stage_file_title: string;
  state_file_title: string;
}

interface FilesData {
  Files: File[];
  total_pages: number;
}

interface UseFilesDataResponse {
  data: FilesData | null;
  loading: boolean;
  error: string | null;
}

const useFile = (page: number): UseFilesDataResponse => {
  const [data, setData] = useState<FilesData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}/textprovider/files`, {
          params: {
            page: page,
          },
        });
        /*const response = await axios.get(`${MAIN_URL}textprovider/files`);*/
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  return { data, loading, error };
};

export default useFile;
