import { useState, useEffect } from "react";
import { Filter } from "../../types/Filters";

export const usePersistedFilters = (textId: string) => {
  const [filters, setFilters] = useState<Filter[]>(() => {
    const stored = localStorage.getItem(`filters_${textId}`);
    return stored
      ? JSON.parse(stored)
      : [
          {
            id: 0,
            metric: [],
            symbol: [],
            value: [],
          },
        ];
  });

  useEffect(() => {
    localStorage.setItem(`filters_${textId}`, JSON.stringify(filters));
  }, [filters, textId]);

  return [filters, setFilters] as const;
};
